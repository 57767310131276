import styled from "styled-components";

export const Container = styled("div")<{ margin?: number }>`
  ${(props) => {
    const { breakpoints } = props.theme;
    const { gutter, maxWidth, margin } = props.theme.grid;

    let styles = `
      width: 100%;
      padding-right: ${props.margin || margin}px;
      padding-left: ${props.margin || margin}px;
      margin-right: auto;
      margin-left: auto;

      @media ( max-width: 767px ) {
        padding-right: ${gutter}px;
        padding-left: ${gutter}px;
      }
      `;

    Object.keys(breakpoints).forEach((breakpoint) => {
      styles += `
        @media ( min-width: ${breakpoints[breakpoint]}px ) {
          max-width: ${maxWidth[breakpoint]};
        }
      `;
    });

    return styles;
  }}
`;
