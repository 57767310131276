import React from "react";
import { FlexContainer, Line, Separator } from "../../../StyledComponents";
import { RiOrganizationChart, RiSlideshow3Fill } from "react-icons/ri";
import { BsFillImageFill } from "react-icons/bs";
import {
  HeaderDropDownTitle,
  Img,
  ImgSvgWrapper,
  ListItem,
  ListItemText,
} from "../../styles";
import ByteTrayced from "../../../../assets/icons/byte-traced.svg";
import VideoTrayced from "../../../../assets/icons/video-traced.svg";
import { HiDocumentText } from "react-icons/hi";
import { Icon } from "../../../Icon/styles";

const Component = () => {
  const items = [
    {
      label: "Videos",
      link: "https://academy.academia.batan.coop/sign-in",
      iconSvg: VideoTrayced,
    },
    {
      label: "Imagenes",
      link: "https://academy.academia.batan.coop/sign-in",
      icon: BsFillImageFill,
    },
    {
      label: "Bytes",
      link: "https://academy.academia.batan.coop/sign-in",
      iconSvg: ByteTrayced,
    },
    {
      label: "SlideShows",
      link: "https://academy.academia.batan.coop/sign-in",
      icon: RiSlideshow3Fill,
    },
    {
      label: "Cdocs",
      link: "https://academy.academia.batan.coop/sign-in",
      icon: HiDocumentText,
    },
    {
      label: "Trayectos",
      link: "https://academy.academia.batan.coop/sign-in",
      icon: RiOrganizationChart,
    },
  ];

  return (
    <>
      <HeaderDropDownTitle>Subir contenido</HeaderDropDownTitle>
      <Separator size={8.5}></Separator>
      <Line height={0.5}></Line>
      <Separator size={7}></Separator>
      <FlexContainer direction="column" gap="8px">
        {items.map((item) => {
          return (
            <ListItem
              onClick={(ev) => {
                window.open(item.link, "_autoblank");
              }}
              key={item.label}
            >
              <FlexContainer align="center" gap="7px">
                {item.icon ? (
                  <item.icon
                    width={22}
                    height={22}
                    size={22}
                    color="#2496ed"
                  ></item.icon>
                ) : (
                  <Icon
                    icon={item.iconSvg}
                    color={"#2496ed"}
                    size={"22px"}
                  ></Icon>
                )}
                <ListItemText>{item.label}</ListItemText>
              </FlexContainer>
            </ListItem>
          );
        })}
      </FlexContainer>
    </>
  );
};

export default Component;
