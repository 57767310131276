import React from "react";
import Grid from "../../components/Grid";
import { Paragraph, Title } from "./style";

const Component = () => {
  return (
    <Grid.Container>
      <Paragraph>DEV PLACE TECHNOLOGIES, Inc.</Paragraph>
      <Paragraph>Onwards, Constana.</Paragraph>
      <Title>Terms and Conditions</Title>
      <Paragraph>Effective Date: 01/23/2023</Paragraph>
      <Paragraph>Welcome to Constana!</Paragraph>
      <Paragraph>
        These Terms and Conditions ("Terms") govern your access and use of the
        Constana platform and services ("Services") provided by Constana ("we,"
        "us," or "our"). By using Constana, you agree to be bound by these
        Terms. If you do not agree with any part of these Terms, you may not
        access or use our Services.
      </Paragraph>
      <Paragraph>Acceptance of Terms</Paragraph>
      <Paragraph>
        1.1 Agreement: By accessing or using Constana, you affirm that you are
        at least 13 years old and have the legal capacity to enter into these
        Terms. If you are under 13, you may only use Constana with parental or
        legal guardian consent.
      </Paragraph>
      <Paragraph>
        1.2 Modification: We reserve the right to modify these Terms at any
        time, and the updated version will be effective immediately upon
        posting. Your continued use of Constana after any changes constitutes
        your acceptance of the modified Terms. It is your responsibility to
        review the Terms periodically for any updates.
      </Paragraph>
      <Paragraph> Use of Constana</Paragraph>
      <Paragraph>
        2.1 License: Subject to your compliance with these Terms, we grant you a
        limited, non-exclusive, non-transferable, and revocable license to
        access and use Constana solely for your personal, non-commercial use.
      </Paragraph>
      <Paragraph>
        2.2 Account Registration: Some features of Constana may require you to
        create an account. You are responsible for maintaining the
        confidentiality of your account credentials and for any activities that
        occur under your account. You agree to provide accurate and complete
        information during the registration process.
      </Paragraph>
      <Paragraph>
        2.3 User Content: Constana may allow you to post, share, or upload
        content, including comments, images, videos, and other materials ("User
        Content"). You retain ownership of your User Content, but by posting it
        on Constana, you grant us a worldwide, royalty-free, and non-exclusive
        license to use, reproduce, modify, adapt, publish, and display your User
        Content in connection with the Services.
      </Paragraph>

      <Paragraph>2.4 Prohibited Activities: You agree not to:</Paragraph>
      <Paragraph>
        a) Use Constana for any illegal, unauthorized, or malicious purposes;
      </Paragraph>
      <Paragraph>
        b) Interfere with the proper functioning of Constana or violate any
        applicable laws or regulations;
      </Paragraph>
      <Paragraph>
        c) Engage in any unauthorized data mining, scraping, or harvesting;
      </Paragraph>
      <Paragraph>
        d) Impersonate any person or entity, or falsely represent your
        affiliation with any entity;
      </Paragraph>
      <Paragraph>
        e) Circumvent, disable, or attempt to bypass any security or access
        controls of Constana.
      </Paragraph>
      <Paragraph>Intellectual Property</Paragraph>
      <Paragraph>
        3.1 Ownership: All content and materials on Constana, including text,
        graphics, logos, images, videos, software, and designs, are the property
        of Constana or its licensors and are protected by copyright, trademark,
        and other intellectual property laws.
      </Paragraph>
      <Paragraph>
        3.2 Trademarks: "Constana" and the Constana logo are trademarks of
        Constana. You are not granted any right or license to use our trademarks
        without our express written consent.
      </Paragraph>
      <Paragraph>Privacy Policy</Paragraph>
      <Paragraph>
        Your privacy is essential to us. Please refer to our Privacy Policy to
        understand how we collect, use, and protect your personal information on
        Constana. By using Constana, you consent to the practices described in
        our Privacy Policy.
      </Paragraph>
      <Paragraph>Disclaimer of Warranties</Paragraph>
      <Paragraph>
        Constana is provided "as is" and "as available." We make no warranties
        or representations regarding the accuracy, completeness, or reliability
        of the content or features provided on Constana. We disclaim all
        warranties, express or implied, including any warranties of
        merchantability, fitness for a particular purpose, or non-infringement.
      </Paragraph>
      <Paragraph>Limitation of Liability </Paragraph>
      <Paragraph>
        To the maximum extent permitted by applicable law,Constana, its
        officers, directors, employees, and affiliates shall not be liable for
        any direct, indirect, incidental, special, or consequential damages
        arising out of or in any way related to your use of Constana, including
        but not limited to loss of data, profits, or business interruption.
      </Paragraph>
      <Paragraph>Indemnification</Paragraph>
      <Paragraph>
        You agree to indemnify, defend, and hold harmless Constana, its
        officers, directors, employees, and agents from and against any and all
        claims, liabilities, damages, losses, costs, and expenses (including
        attorney's fees) arising out of or related to your use of Constana or
        violation of these Terms.
      </Paragraph>
      <Paragraph>Termination </Paragraph>
      <Paragraph>
        We reserve the right to suspend or terminate your access to Constana, in
        whole or in part, at any time and without notice, for any reason,
        including but not limited to violations of these Terms.
      </Paragraph>
      <Paragraph>Governing Law</Paragraph>
      <Paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of United States of America, without regard to its conflict of laws
        principles.
      </Paragraph>
      <Paragraph>Entire Agreement </Paragraph>
      <Paragraph>
        These Terms constitute the entire agreement between you and
        Constanaregarding your use of Constana and supersede any prior
        agreements or understandings.
      </Paragraph>
      <Paragraph>Contact Us </Paragraph>
      <Paragraph>
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at admin@academia.batan.coop.
      </Paragraph>
      <Paragraph>Last Updated: 06/15/2023</Paragraph>
      <Title>Privacy Policy</Title>
      <Paragraph>Effective Date: 01/23/2023</Paragraph>
      <Paragraph>
        This Privacy Policy outlines how Constana ("we," "us," or "our")
        collects, uses, shares, and protects your personal information when you
        use Constana's platform and services ("Services").
      </Paragraph>
      <Paragraph>Information We Collect </Paragraph>
      <Paragraph>
        1.1 Personal Information: When you create an account or use certain
        features of Constana, we may collect personal information such as your
        name, email address, and profile information.
      </Paragraph>
      <Paragraph>
        1.2 Usage Data: We may collect information about how you use Constana,
        including your interactions with content, searches, and other user
        activities.
      </Paragraph>
      <Paragraph>
        1.3 Cookies and Similar Technologies: Constana uses cookies and similar
        technologies to collect data about your device and usage patterns. This
        helps us enhance your experience and improve our Services.
      </Paragraph>
      <Paragraph>How We Use Your Information </Paragraph>
      <Paragraph>
        2.1 Provide and Improve Services: We use your information to deliver,
        maintain, and improve Constana's Services, as well as to personalize
        your experience on our platform.
      </Paragraph>
      <Paragraph>
        2.2 Communication: We may use your contact information to send you
        service-related announcements, updates, and promotional content. You can
        opt-out of receiving promotional emails.
      </Paragraph>
      <Paragraph>
        2.3 Analytics and Research: We analyze user data to understand trends,
        perform research, and improve our Services.
      </Paragraph>
      <Paragraph>How We Share Your Information </Paragraph>
      <Paragraph>
        3.1 Service Providers: We may share your information with third-party
        service providers to assist us in delivering our Services.
      </Paragraph>
      <Paragraph>
        3.2 Legal Requirements: We may disclose your information if required by
        law, court order, or government regulation.
      </Paragraph>
      <Paragraph>Data Security </Paragraph>
      <Paragraph>
        We implement reasonable security measures to protect your personal
        information from unauthorized access, disclosure, alteration, or
        destruction.
      </Paragraph>
      <Paragraph>Your Choices </Paragraph>
      <Paragraph>
        You have the right to access, correct, or delete your personal
        information. You can update your account settings or contact us for
        assistance.
      </Paragraph>
      <Paragraph> Children's Privacy </Paragraph>
      <Paragraph>
        Constana is not intended for children under 13 years of age. If we
        become aware that we have collected personal information from a child
        under 13, we will take steps to delete it.
      </Paragraph>
      <Paragraph>Third-Party Links </Paragraph>
      <Paragraph>
        Our platform may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of such third
        parties.
      </Paragraph>
      <Paragraph>Changes to this Privacy Policy </Paragraph>
      <Paragraph>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the updated policy on our
        platform.
      </Paragraph>
      <Paragraph>Contact Us </Paragraph>
      <Paragraph>
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, please contact us at admin@academia.batan.coop.
      </Paragraph>
      <Paragraph>Last Updated: 06/15/2023</Paragraph>
    </Grid.Container>
  );
};

export default Component;
